import BaseCollection from "@sourceflow-uk/sourceflow-sdk/base_collection";

/**
 *
 * @param data
 * @param mappings
 * @returns {*}
 */
export default function useSourceFlowCollection(data, mappings = null) {
  let collection = new BaseCollection(data, "en").getItems();

  if (!mappings) {
    return collection;
  }

  const keys = Object.keys(mappings);

  return collection.map((item) => {
    keys.forEach((key) => {
      if (key in item && item[key] !== null) {
        const collection = new BaseCollection(mappings[key], "en").getItems();

        item[`__${key}`] = item[key];
        item[key] = Array.isArray(item[key])
          ? collection.filter((m) => item[key].includes(m.id))
          : collection.find((m) => item[key].includes(m.id));
      }
    });

    return item;
  });
}
